import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import { Table, Card, Container, Row, Col } from "react-bootstrap";
import { navigate } from "gatsby";
import Trainingresources from '../components/trainingresources';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Container mdxType="Container">
  <Row className="px-4" mdxType="Row">
    <Col mdxType="Col">
      BY-COVID sustains many open science resources which contribute to the goal of making infectious disease data open and available for everyone. The resources listed below receive funding directly from the BY-COVID project.
      <Container className="full-width mt-4" mdxType="Container">
        <Row mdxType="Row">
            <Col className="align-items-stretch" xs={12} sm={6} lg={6} mdxType="Col">
              <Card className="mb-4 border text-center" mdxType="Card">
                <div className="card-image">
                  <a href="https://www.covid19dataportal.org/">
                      <Card.Img variant="top" src="/CV19DP_logo_oneliner2.svg" className="p-3 embed-responsive-item" style={{
                        "background": "#fffff",
                        "width": "85%"
                      }} />
                  </a>
                </div>
                  <Card.Body>
                    <h3><a href="https://www.covid19dataportal.org/data-hubs">SARS-CoV-2 Data Hubs</a></h3>
                    Data and tools to accelerate coronavirus research
                  </Card.Body>
              </Card>
            </Col>
            <Col className="d-flex align-items-stretch" xs={12} sm={6} lg={6} mdxType="Col">
              <Card className="mb-4 border text-center" mdxType="Card">
                <div className="card-image">
                  <a href="https://www.infectious-diseases-toolkit.org/">
                    <Card.Img variant="top" src="/idt_main_logo.svg" className="p-3 embed-responsive-item" style={{
                        "background": "#fff",
                        "width": "85%"
                      }} />
                  </a>
                </div>
                <Card.Body>
                  <Card.Link href="https://www.infectious-diseases-toolkit.org/" className="pt-3 fs-55">Guidelines, examples and tools relating to infectious disease data</Card.Link>
                </Card.Body>
              </Card>
            </Col>
        </Row>
        <Row mdxType="Row">
            <Col className="align-items-stretch" xs={12} sm={6} lg={6} mdxType="Col">
              <Card className="mb-4 border text-center" mdxType="Card">
                <div className="card-image">
                  <a href="https://www.ebi.ac.uk/ena/pathogens/v2/">
                    <Card.Img variant="top" src="/pathogens_logo_1.png" className="p-3 embed-responsive-item wd-75" style={{
                        "background": "#fff",
                        "width": "75%"
                      }} />
                  </a>
                </div>
                <Card.Body>
                  <h3><a href="https://www.pathogensportal.org/datahubs">Pathogen Data Hubs</a></h3>
                    Data and tools to accelerate pathogen research
                </Card.Body>
              </Card>
            </Col>
            <Col className="d-flex align-items-stretch" xs={12} sm={6} lg={6} mdxType="Col">
              <Card className="mb-4 border text-center" mdxType="Card">
                <div className="card-image">
                  <a href="https://fairsharing.org/3773">
                    <Card.Img variant="top" src="/fairsharing-logo.svg" className="p-3 embed-responsive-item" style={{
                        "background": "#fff"
                      }} />
                  </a>
                </div>
                <Card.Body>
                  <Card.Link href="https://fairsharing.org/3773" className="pt-3 fs-55">Mapping and interlinking databases, standards and policies</Card.Link>
                </Card.Body>
              </Card>
            </Col>
        </Row>
        <Row mdxType="Row">
            <Col className="d-flex align-items-stretch" xs={12} sm={6} lg={6} mdxType="Col">
              <Card className="mb-4 border text-center" mdxType="Card">
                <div className="card-image">
                  <a href="https://www.researchobject.org/ro-crate/">
                    <Card.Img variant="top" src="/ro-crate-wide.svg" className="p-3 embed-responsive-item" style={{
                        "background": "#fff",
                        "width": "70%"
                      }} />
                  </a>
                </div>
                <div className="card-body">
                  <Card.Link href="https://www.researchobject.org/ro-crate/" className="pt-3 fs-55">Packaging research data with their metadata</Card.Link>
                </div>
              </Card>
            </Col>
            <Col className="align-items-stretch" xs={12} sm={6} lg={6} mdxType="Col">
              <Card className="mb-4 border text-center" mdxType="Card">
                <div className="card-image">
                  <a href="https://workflowhub.eu/">
                    <Card.Img variant="top" src="/workflowhub.svg" className="p-3 embed-responsive-item" style={{
                        "background": "#fff",
                        "width": "85%"
                      }} />
                  </a>
                </div>
                <Card.Body>
                  <Card.Link href="https://workflowhub.eu/" className="pt-3 fs-55">Describing, sharing and publishing scientific computational workflows</Card.Link>
                </Card.Body>
              </Card>
            </Col>
        </Row>
        <Row mdxType="Row">
            <Col className="align-items-stretch" xs={12} sm={6} lg={6} mdxType="Col">
              <Card className="mb-4 border text-center" mdxType="Card">
                <div className="card-image">
                  <a href="https://covid.pages.uni.lu/index">
                    <Card.Img variant="top" src="/COVID19-dmap-project.svg" className="p-3 embed-responsive-item" style={{
                        "background": "#fff"
                      }} />
                  </a>
                </div>
                <Card.Body>
                  <h3><a href="https://covid.pages.uni.lu/">COVID-19 Disease Map</a></h3>
                  A repository of SARS-CoV-2 virus-host interaction mechanisms
                </Card.Body>
              </Card>
            </Col>
            <Col className="align-items-stretch" xs={12} sm={6} lg={6} mdxType="Col">
              <Card className="mb-4 border text-center" mdxType="Card">
                <div className="card-image">
                  <a href="https://by-covid.org/usecases">
                    <Card.Img variant="top" src="/by-covid-citizen-engagement.png" className="p-3 embed-responsive-item" style={{
                        "background": "#fff"
                      }} />
                  </a>
                </div>
                <Card.Body>
                  <h3><a href="/usecases">BY-COVID citizen engagement</a></h3>
                  An educational toolkit on data sharing for pandemic preparedness
                </Card.Body>
              </Card>
            </Col>
        </Row>
      </Container>  
    </Col>
  </Row>
  <Row className="px-4" mdxType="Row">
   <Col mdxType="Col">
      <h3 className="pt-5 pb-3">BY-COVID training materials</h3>
    <Trainingresources mdxType="Trainingresources" />
   </Col>
 </Row>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      